/*******************************
        Custom Elements
*******************************/
#loader {
    background: #fff!important;
    position: fixed;
}
.scrollable {
    max-height: 200px; 
    overflow-y: scroll;
}
.covered.image,
.covered.image img,
.covered.img.modal img {
    object-fit: cover;
}
.contained.image,
.contained.image img,
.contained.img.modal img {
    object-fit: contain;
}
.custom.message {
    padding: 0.7em;
}
.ui.custom.zoom.image img:hover,
.ui.custom.zoom.image:hover {
    cursor: zoom-in;
}
.img.modal .image.content {
    max-height: 90vh;
}
label.pointer:hover,
.custom.image .move.icon:hover {
    cursor: pointer;
}
.custom.checkbox{
    padding-top: 0.4em;
}
.custom.banner.image {
    width: 100%;
}
.custom.banner.image > img {
    margin: 0 auto;
    object-fit: contain;
    height: 240px;
}
.img.slider.modal {
}
.img.slider.modal > img {
}

.hidden.button { 
    display:none;
}
/*******************************
        General & Layout
*******************************/
body.pushable > .pusher {
    background: #e2e2e2!important;
}
.vertical.stripe.segment {
    padding: 8em 0em;
}
.vertical.segment {
    border: 0!important; 
}

/*******************************
       Data Table Search
*******************************/
.dataTables_wrapper .dataTables_filter.input,
.dataTables_wrapper .dataTables_filter.input > label {
    width: 100%;
}
.dataTables_wrapper .dataTables_filter.input > label input {
    width: 70%;
}
.ui.search > .results {
    width: 35em;
    max-height: 15em;
    overflow-y: auto;
}

.ui.search > .results .result {
    padding: 0.4em 0.8em!important;
}

/*******************************
        Products Search
*******************************/
.ui.product.search input {
    border-radius: 0.28571429rem;
}

/*******************************
            Icons
*******************************/
a:hover .ui.icon,
.hovered:hover {
    cursor: pointer;
}

/*******************************
            Forms
*******************************/
.form .single.fields {
    margin: 0;
}
/*******************************
          Product Page
*******************************/
/*Variations*/
.variations > .row {
    padding-top: 0.4rem !important;
    padding-bottom: 0.4rem !important;
}
.variations > .row:nth-child(2n) {
    background-color: #f5f5f5;
}
/*******************************
          Login Page
*******************************/
.login.wrapper {
    min-height: 75vh;
}
.login.wrapper>.column {
    width: 100%!important;
    max-width: 450px;
}

/*******************************
            Libraries
*******************************/
/*Chosen - Semantic UI & Chosen input conflict fix*/
.chosen-container-single .chosen-search input[type="text"] {
    background: #fff url(chosen-sprite.png) no-repeat 100% -20px!important;
    background: url(chosen-sprite.png) no-repeat 100% -20px!important;
}
/*Chosen - Styling Semantic UI like*/
.chosen-container-single .chosen-single {
    background: #fff!important;
    box-shadow: none;
    border: 1px solid rgba(34,36,38,.15);
    height: 37px;
    line-height: 37px;
    border-radius: .28571429rem;
    -webkit-transition: box-shadow .1s ease,width .1s ease;
    transition: box-shadow .1s ease,width .1s ease;
    color: rgba(0,0,0,.87);
}
.chosen-container-multi .chosen-choices li.search-choice {
    white-space: normal;
    box-shadow: 0 0 0 1px rgba(34,36,38,.15) inset;
    background-image: none;
    color: rgba(0,0,0,.6);
    text-transform: none;
    font-weight: 700;
    border: 0 solid transparent;
    padding: 4px 20px 4px 5px;
}
.chosen-container-active.chosen-with-drop .chosen-single,
.chosen-container.chosen-with-drop .chosen-drop,
.chosen-container .chosen-drop,
.chosen-container-multi .chosen-choices {
    border: 1px solid rgba(34,36,38,.15);
    box-shadow: none;
}
.chosen-container-multi .chosen-choices li.search-choice,
.chosen-container-multi .chosen-choices li.search-choice:hover {
    background-position-x: 2px;
}
.chosen-container-single .chosen-single abbr {
    top: 13px;
}
.chosen-container.chosen-with-drop .chosen-drop {
    border-top-width: 0!important;
    box-shadow: 0 2px 3px 0 rgba(34,36,38,.15);
}
.chosen-container-single .chosen-single div b {
    background-position: 0px 9px;
}
.chosen-container-active.chosen-with-drop .chosen-single div b {
    background-position: -18px 9px;
}

/*Jquery UI - Sortable*/
#product_images_box .custom.image {
    width: 100%;
}
#sliders_images_box {
    margin:0;
}
#sliders_images_box > .item:before {
    right: 1.5em;
    top: 1.5em;
    margin: 0!important;
    font-weight: bold;
    z-index: 10;
}
#product_images_box .custom.image img,
#sliders_images_box .custom.image img {
    width: 100%;
    height: 170px;
    object-fit: contain;
}
#product_images_box .ui-sortable-placeholder,
#sliders_images_box .ui-sortable-placeholder { 
    border: 1px solid rgba(34, 36, 38, 0.15);
    box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15);
    border-radius: 0.28571429rem;
    visibility: visible !important; 
}
#product_images_box .ui-sortable-placeholder *,
#sliders_images_box .ui-sortable-placeholder * { 
    visibility: hidden; 
}
#product_image_form_new .choose-file {
    padding-top: 0.4em;
    padding-bottom: 0.4em;
}

/*Semantic UI - product categories accordion tree view*/
.ui.categories.accordion .accordion {
    margin: 0;
}
.ui.categories.accordion .content {
    padding-top:0!important;
    padding-bottom:0!important;
}
.ui.categories.accordion >.content {
    padding: 0!important;
}
.ui.categories.accordion >.content>.accordion {
    margin: 0!important;
    box-shadow: none;
}
.ui.categories.accordion >.content>.accordion .accordion {
    box-shadow: none;
}
.ui.categories.accordion >.content>.accordion .accordion .title {
    border: 0;
}

/*******************************
         Elements Fixes
*******************************/
.ui.grid > .stretched.row > .column > .header {
    -webkit-box-flex: 0!important;
    flex-grow: 0!important;
}
.ui.items > .item > .content > .description {
    margin: 0;
}

/*******************************
            Footer
*******************************/
.footer.segment {
    z-index: 10;
    margin-top: -47px!important;

    width: 100%;
    border-radius: 0;
    border-left: 0;
    border-right: 0;
}

.ui.input.text.right.aligned input {
    text-align: right;
}
.ui.mini.header {
    font-size: 0.8em;
}